import React from "react";
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { Heading1 } from "../components/Typography/Typography";
import TheoryThumbnail from "../components/TheoryThumbnail/TheoryThumbnail";
import styles from "./pagestyles/TheoryPages.module.scss";

const TheoryPage = ({ location }) => {
  const {
    sanityTheoryOverview: { title, theoryPages },
  } = useStaticQuery(graphql`
    query {
      sanityTheoryOverview(_id: { regex: "/theoryOverview$/" }) {
        title
        theoryPages {
          _key
          title
          description
          slug {
            current
          }
        }
      }
    }
  `);
  return (
    <Layout location={location} standardPadding maxWidth>
      <SEO title="Fag" />
      <Heading1>{title}</Heading1>
      <br />
      <div className={styles.theoryThumbnailGrid}>
        {theoryPages.map((theoryThumbnail, index) => (
          <TheoryThumbnail key={`theory-page-${index}`} {...theoryThumbnail} />
        ))}
      </div>
    </Layout>
  );
};

export default TheoryPage;
