import { Link } from "gatsby";
import React from "react";
import Icon from "../icon";
import { Body1, Heading5 } from "../Typography/Typography";
import styles from "./TheoryThumbnail.module.scss";

const TheoryThumbnail = ({ title, description, slug }) => {
  return (
    <Link to={slug.current} className={styles.thumbnail}>
      <Heading5>
        <span>{title}</span>
        <Icon icon="arrowRight" />
      </Heading5>
      <Body1>{description}</Body1>
    </Link>
  );
};

export default TheoryThumbnail;
